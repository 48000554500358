import React from "react";
import "../styles/Karriere.css";
import {
  Card,
  Image,
  Text,
  Badge,
  Button,
  Group,
  Spoiler,
  Space,
  Divider,
  useMantineTheme,
} from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";
import Aos from "aos";
import "aos/dist/aos.css";
import aboutBack from "../assets/aboutBack.jpg";
import JobB2 from "../assets/jobB2.jpg";

var scrolly = true;
function Karriere() {
  return (
    <>
      <div
        id="KarriereID"
        style={{
          backgroundImage: `url(${JobB2})`,
          backgroundSize: "100% 100%",
          height: "auto",
          zIndex: 1,
        }}
      >
        <h3 className="ÜberUnsHome">Kariéra</h3>
        <div className="FadeDownCards" id="Karriere">
          <div
            className="CardsChange"
            style={{ width: "30%", margin: "20vh 0 0 0" }}
            data-aos="fade-down"
            data-aos-delay="750"
            data-aos-duration="400"
            data-aos-easing="ease-in-cubic"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor="#Karriere"
          >
            <Card shadow="sm" padding="lg">
              <Card.Section>
                <Image
                  src={require("../assets/Card5.jpg")}
                  height={160}
                  alt="Norway"
                />
              </Card.Section>

              <div
                data-aos="fade-down"
                data-aos-delay="750"
                data-aos-duration="400"
                data-aos-easing="ease-in-cubic"
                data-aos-mirror="true"
                data-aos-once="false"
                data-aos-anchor="#Karriere"
              >
                <Group
                  position="apart"
                  style={{ marginBottom: 5, marginTop: 5 }}
                >
                  <Text className="CardTitel">
                    <h4>Software Testing</h4>
                  </Text>
                </Group>

                <Spoiler
                  maxHeight={170}
                  showLabel="Ďalšie reklamy. "
                  hideLabel="Menej reklám."
                >
                  {
                    <Text size="sm" className="CardText">
                      V rýchlo sa rozvíjajúcom sektore IT došlo k výraznému
                      výrazný nárast zložitosti softvérových produktov.
                      softvérových produktov. Skúsenosti ukazujú, že každý
                      dostatočne zložitý systém bude obsahovať softvérové chyby.
                      a spolu s neustále sa znižujúcou odolnosťou voči poruchám
                      tolerancie používateľov, význam testovania softvéru je
                      testovanie softvéru sa výrazne zvyšuje. Dôsledným
                      používaním testovacích prostriedkov, je možné odhaliť
                      chyby v počiatočnom štádiu. a výsledné náklady na chyby
                      možno minimalizovať. S potešením preto oznamujeme, že
                      spoločnosť FB Consulting und Trade GmbH úlohy softvérového
                      testera, Inžinier automatizácie testovania a manažér
                      testovania ako súčasť našej tím a dať vám ich k dispozícii
                      na realizáciu vášho projekty. Veľký význam prikladáme aj
                      našich zamestnancov, a preto sú členmi rakúskej skúšobnej
                      a sú preto členmi rakúskej skúšobnej komisie.
                    </Text>
                  }
                </Spoiler>
              </div>
            </Card>
          </div>
          <div
            className="CardsChange"
            style={{ width: "30%", margin: "10vh 0 0 0" }}
            data-aos="fade-down"
            data-aos-delay="500"
            data-aos-duration="400"
            data-aos-easing="ease-in-cubic"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor="#Karriere"
          >
            <Card shadow="sm" padding="lg">
              <Card.Section>
                <Image
                  src={require("../assets/Card6.jpg")}
                  height={160}
                  alt="Norway"
                />
              </Card.Section>
              <div
                data-aos="fade-down"
                data-aos-delay="500"
                data-aos-duration="400"
                data-aos-easing="ease-in-cubic"
                data-aos-mirror="true"
                data-aos-once="false"
                data-aos-anchor="#Karriere"
              >
                <Group
                  position="apart"
                  style={{ marginBottom: 5, marginTop: 5 }}
                >
                  <Text className="CardTitel">
                    <h4>Projektový manažér</h4>
                  </Text>
                </Group>

                <Spoiler
                  maxHeight={170}
                  showLabel="Ďalšie reklamy. "
                  hideLabel="Menej reklám."
                >
                  {
                    <Text size="sm" className="CardText">
                      Medzi povinnosti projektového manažéra patrí plánovanie
                      zahŕňa plánovanie, organizáciu a kontrola IT projektov.
                      Zastúpené aj v oblasti je plánovanie zdrojov. Projektoví
                      manažéri sú preto projektoví manažéri sa rozhodujúcim
                      spôsobom podieľajú na a úspešné dokončenie IT projektov.
                      IT projekty. Vynikajúci komunikačné zručnosti, schopnosť
                      pracovať v orientácia na zákazníka sú charakteristickými
                      znakmi nášho projektu riadenia projektu. V mene našich
                      zákazníkov s pomocou našich kolegov sa nám podarilo
                      projekty v klasickom aj agilnom prostredí. prostredie boli
                      úspešne implementované. Od ďalšieho vývoja rozvoj našich
                      zamestnancov je základným aspektom našej filozofia, naši
                      zamestnanci sú školení podľa zamestnanci sú certifikovaní
                      podľa certifikované.
                    </Text>
                  }
                </Spoiler>
              </div>
            </Card>
          </div>
          <div
            className="CardsChange"
            style={{ width: "30%", margin: "0 0 0 0" }}
            data-aos="fade-down"
            data-aos-delay="250"
            data-aos-duration="400"
            data-aos-easing="ease-in-cubic"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor="#Karriere"
          >
            <Card shadow="sm" padding="lg">
              <Card.Section>
                <Image
                  src={require("../assets/Card3.jpg")}
                  height={160}
                  alt="Norway"
                />
              </Card.Section>
              <div
                data-aos="fade-down"
                data-aos-delay="250"
                data-aos-duration="400"
                data-aos-easing="ease-in-cubic"
                data-aos-mirror="true"
                data-aos-once="false"
                data-aos-anchor="#Karriere"
              >
                <Group
                  position="apart"
                  style={{ marginBottom: 5, marginTop: 5 }}
                >
                  <Text className="CardTitel">
                    <h4>Software Developer</h4>
                  </Text>
                </Group>

                <Spoiler
                  maxHeight={170}
                  showLabel="Ďalšie reklamy. "
                  hideLabel="Menej reklám."
                >
                  {
                    <Text size="sm" className="CardText">
                      V odvetví IT existuje množstvo technológií a rovnako veľa
                      odborníkov je potrebných na úspešné úspešne implementovať
                      softvérový projekt. Úloha vývojár má zásadný význam pre
                      úspech projektu a úspešnosť projektu a orientácia na
                      riešenie, ochota komunikovať, tímová orientácia a vôľa a
                      ochota neustále zlepšovať svoje zručnosti sú základom
                      našich vývojárov. Ako váš IT partner vám môžeme ponúknuť
                      Spoločnosť FB Consulting vám môže ponúknuť širokú škálu
                      rôznych vývojové služby. Angular, Java, C# a React je len
                      niekoľko príkladov technológií, ktoré naša denne používajú
                      naši zamestnanci. Prosím, neváhajte neváhajte nás
                      kontaktovať, aby sme mohli vám môže pripraviť ponuku šitú
                      na mieru vašim potrebám a požiadavkám. a požiadavky. Aj v
                      tomto vždy sa snažíme poskytovať našim zamestnancom
                      certifikáty a s príslušnými certifikátmi a ďalším
                      vzdelávaním pre vašu ďalšie školenia týkajúce sa vašej
                      oblasti odbornosti.
                    </Text>
                  }
                </Spoiler>
              </div>
            </Card>
          </div>
        </div>
        <div
          data-aos="fade-down"
          data-aos-delay="250"
          data-aos-duration="400"
          data-aos-easing="ease-in-cubic"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor="#Karriere"
          style={{ height: "10vh", zIndex: 1 }}
        >
          <a href="/Bewerber">
            <Button className="Button1" variant="subtle">
            Nájdite si prácu tu!
            </Button>
          </a>
        </div>
      </div>
    </>
  );
}

export default Karriere;
