import React from "react";
import Navbar2 from "../../components/Navbar2";
import "../../styles/JobVorlage.css";
import JobVorlageB from "../../assets/JobVorlageB.jpg";
import Merve from "../../assets/merve.jpg";
import BewerberB from "../../assets/aboutBack.jpg";
import { Row, Col } from "antd";
import {
  EuroOutlined,
  CoffeeOutlined,
  DribbbleOutlined,
  HomeOutlined,
  LaptopOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

import {
  Image,
  Text,
  BackgroundImage,
  Center,
} from "@mantine/core";

function JobVorlage() {
  return (
    <>
      <Navbar2 />
      <BackgroundImage className="JobBild" src={JobVorlageB}>
        <Center p="md">
          <Text className="JobVorlageText" color="#fff">
            C# Softwareentwickler
            <p>Wien | Vollzeit</p>
          </Text>
        </Center>
      </BackgroundImage>
      {/* ------------------------------------------------Links--------------------------------------------------------------------------------*/}
      <BackgroundImage className="WasMachtUns" src={BewerberB}>
        <Row className="Divider">
          <Col span={9} className="JobLinks">
            <ul align="left">
              <h2>Deine Aufgaben:</h2>
              <li>Design und Implementierung von Backend Applikationen</li>
              <li>
                Neu- oder Weiterentwicklung von Softwarelösungen und Services
                mit Schwerpunkt C#
              </li>
              <li>Testen von neuen Produkt-Features</li>
              <li>Mitarbeit an aufregenden, innovativen Projekten</li>
            </ul>

            <ul align="left">
              <h2>Du begeisterst uns durch:</h2>
              <li>
                Abgeschlossenes Studium mit IT-Schwerpunkt oder gleichwertige
                Ausbildung
              </li>
              <li>Mindestens 2 Jahre Berufserfahrung</li>
              <li>
                Kenntnisse in SQL (MS SQL-Server / T-SQL), Datenmodellierung und
                C# / .NET
              </li>
              <li>
                Kenntnisse in ASP.NET, ASP.NET MVC und Javascript (JQuery)
              </li>
              <li>
                Idealerweise hast du Kenntnisse über Containier mit Docker und
                Source Code Verwaltung in GitLab / GitHub
              </li>
              <li>Anbindung an Schnittstellen mit REST, SOAP, JSON</li>
              <li>
                Kenntnisse in HTML 5, JavaScript, Entity Framework, Microsoft
                TFS
              </li>
              <li>Genaue und sorgfältige Arbeitsweise</li>
              <li>
                Sehr gute Deutsch- und Englischkenntnisse in Wort und Schrift
              </li>
              <li>Entwicklung von Backend- Komponenten mit .NET</li>
            </ul>
            {/* --------------------------------------------Rechts------------------------------------------------------------------------------------*/}
          </Col>
          <Col span={9} className="Icons">
            <ul align="left">
              <h2>Was wir dir anbieten :</h2>
              <li>
                Gezielte Karriereplanung und ausgezeichnete
                Entwicklungsmöglichkeiten (bezahlte Zertifizierungen und
                Trainings)
              </li>
              <li>Attraktive Vergütung</li>
              <li>
                Öffentlich gut erreichbares Büro – mitten auf der Mariahilfer
                Straße
              </li>
              <li>Hands-on-Mentalität</li>
              <li>Mitarbeiterevents </li>
              <li>flache Hierarchien</li>
              <li>flexible Arbeitszeiten sowie Home-Office Möglichkeiten </li>
              <li>
                Free Drinks & Snacks nach deiner Wahl und weitere Benefits
              </li>
              <li>
                State-of-Art Entwicklernotebooks werden zur Verfügung gestellt
              </li>
              <li>Bis zu 100% Homeoffice möglich</li>
            </ul>

            <EuroOutlined />
            <CoffeeOutlined />
            <DribbbleOutlined />
            <HomeOutlined />
            <LaptopOutlined />

            <p>
              Für diese Position bieten wir eine Bezahlung nach dem
              IT-Kollektivvertrag. Je nach konkreter Qualifizierung und
              Erfahrung ist eine Bereitschaft zur Überzahlung gegeben.
            </p>
            <Image className="MerveB2" width={180} height={180} src={Merve} />
            <h2>Merve Celik</h2>
            <p>Deine Ansprechperson bei Fragen rund um Deinen neuen Job!</p>
            <p>
              <MailOutlined /> email: merve.celik@fb-c.at
            </p>
            <p>
              <PhoneOutlined /> tel: 0678 123456789
            </p>
          </Col>
        </Row>
      </BackgroundImage>

      {/* --------------------------------------------------------------------------------------------------------------------------------*/}

      {/* --------------------------------------------------------------------------------------------------------------------------------*/}
    </>
  );
}

export default JobVorlage;
