import React from "react";
import "../../styles/Leistungen.css";
import Navbar2 from "../../components/Navbar2";
import Gallerie from "../../components/Gallerie";
import { Accordion } from "@mantine/core";
import { Row, Col } from 'antd';

function DSGVO() {
  return (
    <>
      <Navbar2 />
      <Gallerie />
      <div id="Leistungen">
      <Row>
      <Col span={6}>
        <div id="LeistungenBeschreibung1">
          <h3>Erklärung zur Informationspflicht</h3>
          <span>
            Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen.
            Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der
            gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen
            Datenschutzinformationen informieren wir Sie über die wichtigsten
            Aspekte der Datenverarbeitung im Rahmen unserer Website.
          </span>
        </div>
        </Col>
        <Col span={6}>
        <div id="LeistungenBeschreibung1">
          <h3>KONTAKT MIT UNS</h3>
          <span>
            Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit
            uns aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der
            Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns
            gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung
            weiter.
          </span>
        </div>
        </Col>
        <Col span={6}>
        <div id="LeistungenBeschreibung1">
          <h3>COOKIES</h3>
          <span>
            Unsere Website verwendet so genannte Cookies. Dabei handelt es sich
            um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät
            abgelegt werden. Sie richten keinen Schaden an. Wir nutzen Cookies
            dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies
            bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie
            ermöglichen es uns, Ihren Browser beim nächsten Besuch
            wiederzuerkennen. Wenn Sie dies nicht wünschen, so können Sie Ihren
            Browser so einrichten, dass er Sie über das Setzen von Cookies
            informiert und Sie dies nur im Einzelfall erlauben. Bei der
            Deaktivierung von Cookies kann die Funktionalität unserer Website
            eingeschränkt sein.
          </span>
        </div>
        </Col>
        <Col span={6}>
        <div id="LeistungenBeschreibung1">
          <h3>IHRE RECHTE</h3>
          <span>
            Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung,
            Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und
            Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten
            gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen
            Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich
            bei der Aufsichtsbehörde beschweren. In Österreich ist dies die
            Datenschutzbehörde.
          </span>
        </div>
        </Col>
        </Row>
      </div>
    </>
  );
}

export default DSGVO;
