import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedin,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
  FooterOhneLink,
  SocialMedia,
} from "./FooterStyles";

const Footer = () => {
  return (
    <Box>
      <Container>
        <Row>
          <Column>
            <Heading>FB CONSULTING </Heading>
            <FooterOhneLink>
            Váš partner pre riadenie projektov a IT poradenstvo!
            </FooterOhneLink>
          </Column>
          <Column>
            <Heading>VÝKON</Heading>
            <FooterOhneLink>Riadenie projektu</FooterOhneLink>
            <FooterOhneLink>Testovanie softvéru</FooterOhneLink>
            <FooterOhneLink>Vývoj softvéru</FooterOhneLink>
          </Column>
          <Column>
            <Heading>KONTAKT</Heading>
            <FooterOhneLink>T +43 1 9974017</FooterOhneLink>
            <FooterLink href="mailto:office@fb-c.at">office@fb-c.at</FooterLink>
            <FooterLink href="/">www.fb-c.at</FooterLink>
            <FooterLink href="/Impressum">Odtlačok</FooterLink>
            <FooterLink href="/DSGVO">GDPR</FooterLink>
          </Column>
          <Column>
            <Heading>ADRESA</Heading>
            <FooterOhneLink>FB Consulting & Trade GmbH</FooterOhneLink>
            <FooterLink
              href="https://goo.gl/maps/kQiDBREzsX6PAaus6"
              target="_blank"
            >
              Neubaugasse 10/3a
            </FooterLink>
            <FooterOhneLink>A-1070 Viedeň</FooterOhneLink>
            <FooterOhneLink>Rakúsko</FooterOhneLink>
          </Column>
          <Column>
            <Heading>SOCIAL MEDIA</Heading>
            <SocialMedia
              href="https://at.linkedin.com/company/fb-consulting-trade-gmbh" target="_blank"
              className="linkedin social"
            >
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </SocialMedia>
            <SocialMedia
              href="https://www.tiktok.com/@fb.consulting" target="_blank"
              className="tiktok social"
            >
              <FontAwesomeIcon icon={faTiktok} size="2x" />
            </SocialMedia>
            <SocialMedia
              href="https://www.instagram.com/fbconsulting.trade/" target="_blank"
              className="instagram social"
            >
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </SocialMedia>
          </Column>
        </Row>
      </Container>
    </Box>
  );
};
export default Footer;
