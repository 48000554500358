import styled from 'styled-components';

export const Box = styled.div`
  padding: 4vh 5vh;
  position: bottom;
  bottom: 0;
  width: 100%;
  z-index:1;


  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 100%); 
  @media (max-width: 1200px) {
    padding: 70px 30px;
  }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 200px;
  grid-gap: 50px;
  margin: 0 auto;
  width: fit-content;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fill, 
                           minmax(200px, 1fr));
  }
`;


export const FooterLink = styled.a`
  color: #fff;
  text-align: left;
  margin-bottom: 0.3vh;
  font-size: 0.75rem;
  text-decoration: underline;
  font-family: poppins;
  line-height: 1.9;
  &:hover {
      color: rgba(234,94,38,255);
      transition: 200ms ease-in;
  }
`;

export const FooterOhneLink = styled.a`
  color: #fff;
  text-align: left;
  margin-bottom: 0.3vh;
  font-size: 0.75rem;
  text-decoration: none;
  font-family: poppins;
  line-height: 2;
  cursor: default;
  &:hover {
      color: white;
      transition: 200ms ease-in;
  }
`;

export const SocialMedia = styled.a`
  color: #fff;
  padding-right: 2vh;
  margin-bottom: 1vh;
  font-size: 0.75rem;
  text-decoration: none;
  text-align: center;
  font-family: poppins;
  &:hover {
      color: rgba(234,94,38,255);
      transition: 200ms ease-in;
  }
  @media (max-width: 1200px) {
    text-align: left;
  }
`;

export const Heading = styled.p`
  font-size: 1.2rem;
  font-family: poppins;
  color: #fff;
  text-align: left;
  margin-bottom: 3vh;
  font-family: "poppins";
`;