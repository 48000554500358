import React from 'react';
import { Carousel } from 'antd';
import "../styles/Gallerie.css";


function onChange(a, b, c) {
    console.log(a, b, c);

}

function BewerberCar() {

    return (
        <>
            <Carousel effect="fade" afterChange={onChange} className="Carousel GallerieBackground1" autoplay autoplaySpeed={3000}>
                <div id="Seite1">
                    <h1 className='GallerieHeader'>FB Consulting</h1>
                    <hr className='Trennlinie'></hr>
                    <h2 className='GallerieHeader2' id='GallerieHeaderId'>Ihr Partner für IT-Projektmanagement, Softwareentwicklung und Software-Testing!</h2>
                </div>
                {/* <div>
                    <h3 className='GallerieHeader'>2</h3>
                </div>
                <div>
                    <h3 className='GallerieHeader'>3</h3>
                </div>
                <div>
                    <h3 className='GallerieHeader'>4</h3>
                </div> */}
            </Carousel>
        </>
    );
}
export default BewerberCar;
