import React from "react";
import Navbar2 from "../../components/Navbar2";
import "../../styles/JobVorlage.css";
import JobVorlageB from "../../assets/JobVorlageB.jpg";
import Merve from "../../assets/merve.jpg";
import BewerberB from "../../assets/aboutBack.jpg";
import { Row, Col } from "antd";
import {
  EuroOutlined,
  CoffeeOutlined,
  DribbbleOutlined,
  HomeOutlined,
  LaptopOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

import { Image, Text, BackgroundImage, Center } from "@mantine/core";
import Video0 from "../../components/video0";

function Projektmanager() {
  return (
    <>
      <Navbar2 />
      <Video0 />
      <BackgroundImage className="JobBild" src={JobVorlageB}>
        <Center p="md">
          <Text className="JobVorlageText" color="#fff">
          Projektový manažér
            <p>Viedeň | Plný úväzok</p>
          </Text>
        </Center>
      </BackgroundImage>
      {/* ------------------------------------------------Links--------------------------------------------------------------------------------*/}
      <BackgroundImage className="WasMachtUns" src={BewerberB}>
        <Row className="Divider">
          <Col span={9} className="JobLinks">
            <ul align="left">
              <h2>Vaše úlohy:</h2>
              <li>
              Plánovanie, riadenie a kontrola
                Projekty vývoja IT softvéru
              </li>
              <li>
              Vedenie projektového tímu, IT partnerov/dodávateľov a zainteresovaných strán z
                zainteresované strany od vypracovania požiadavky až po
                prevádzka údržby
              </li>
              <li>
              priebežná koordinácia s internými a externými projektmi
                klientov projektu, ako aj iných projektov v rámci
                portfólio
              </li>
              <li>
              Poradenstvo klientovi o optimalizovaných scenároch riešenia
                v koordinácii s obchodnými analytikmi
              </li>
              <li>Vytváranie projektových správ</li>
              <li>
              Definovať a implementovať normy a usmernenia pre
                Dokumentácia
              </li>
            </ul>

            <ul align="left">
              <h2>Inšpirujete nás:</h2>
              <li>
              Ukončené štúdium so zameraním na IT alebo rovnocenné
                Vzdelávanie
              </li>
              <li>Prvá relevantná odborná prax je výhodou</li>
              <li>
              Znalosť riadenia požiadaviek na IT alebo
                softvérová architektúra
              </li>
              <li>
              Schopnosť štruktúrovať a riadiť komplexné projekty
              </li>
              <li>
              Vysoká miera samostatnosti, komunikačných zručností a asertivity.
              </li>
              <li>
              Veľmi dobrá znalosť nemčiny a angličtiny v písanej aj hovorenej forme
              </li>
            </ul>
            {/* --------------------------------------------Rechts------------------------------------------------------------------------------------*/}
          </Col>
          <Col span={9} className="Icons">
            <ul align="left">
              <h2>Čo vám ponúkame :</h2>
              <li>
              cielené plánovanie kariéry a vynikajúce možnosti rozvoja (platené certifikácie a
školenia)
              </li>
              <li>Atraktívne odmeňovanie</li>
              <li>
              Verejne prístupná kancelária - priamo uprostred Mariahilfer
                Ulica
              </li>
              <li>Praktická mentalita</li>
              <li>Zamestnanecké podujatia </li>
              <li>ploché hierarchie</li>
              <li>Flexibilný pracovný čas a možnosť home office </li>
              <li>
              Bezplatné nápoje a občerstvenie podľa vlastného výberu a ďalšie výhody
              </li>
            </ul>

            <EuroOutlined />
            <CoffeeOutlined />
            <DribbbleOutlined />
            <HomeOutlined />
            <LaptopOutlined />

            <p>
            Na tejto pozícii ponúkame ročný hrubý plat 38,5 hodiny týždenne.
              ročný hrubý plat od 35 000,00 EUR. V závislosti od betónu
              a skúsenosti, existuje ochota preplácať.
              vzhľadom na to, že.
            </p>
            <Image className="MerveB2" width={180} height={180} src={Merve} />
            <h2>Merve Celik</h2>
            <p>Vaša kontaktná osoba pre otázky týkajúce sa vašej novej práce!</p>
            <a href="mailto:merve.celik@fb-c.at">
              <MailOutlined /> email: merve.celik@fb-c.at
            </a>
            <p>
            <a href="tel:06602906057">
              <PhoneOutlined /> phone number: +43 660 2906057
            </a>
            </p>
          </Col>
        </Row>
      </BackgroundImage>

      {/* --------------------------------------------------------------------------------------------------------------------------------*/}

      {/* --------------------------------------------------------------------------------------------------------------------------------*/}
    </>
  );
}

export default Projektmanager;
