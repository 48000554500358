import React from "react";
import Navbar2 from "../../components/Navbar2";
import Video0 from "../../components/video0";
import BewerberCar from "../../components/BewerberCar";
import "../../styles/Bewerber.css";
import BewerberBild from "../../assets/BewerberBild.jpg";
import Merve from "../../assets/merve.jpg";
import Muco from "../../assets/muco.jpg";
import Card1 from "../../assets/Card1.jpg";
import JobB from "../../assets/jobB.jpg";
import JobB2 from "../../assets/jobB2.jpg";
import achem from "../../assets/logo/achem.png";
import brz from "../../assets/logo/brz.png";
import connect7 from "../../assets/logo/connect7.png";
import etron from "../../assets/logo/etron.png";
import karasan from "../../assets/logo/karasan.png";
import laPausa from "../../assets/logo/laPausa.png";
import pam from "../../assets/logo/pam.jpeg";
import post from "../../assets/logo/post.png";
import remax from "../../assets/logo/remax.png";
import unex from "../../assets/logo/unex.png";
import wienE from "../../assets/logo/wienE.png";
import zurich from "../../assets/logo/zurich.jpeg";
import { Carousel, Row, Col, PageHeader } from "antd";
import {
  FaTrain,
  FaRoute,
  FaEuroSign,
  FaHandsHelping,
  FaBowlingBall,
  FaWindowMinimize,
  FaUserClock,
  FaPizzaSlice,
  FaLaptopHouse,
  FaLaptop,
} from "react-icons/fa";

import { MailOutlined, PhoneOutlined } from "@ant-design/icons";

import {
  Accordion,
  Image,
  Grid,
  Text,
  Button,
  BackgroundImage,
  Center,
} from "@mantine/core";

function onChange(a, b, c) {
  console.log(a, b, c);
}

function Bewerber() {
  return (
    <>
      <Navbar2 />
      <Video0 />
      <BackgroundImage className="WirSuchenDich" src={BewerberBild}>
        <Center p="md">
          <Text className="WirSuchenDichText" color="#fff">
          Hľadáme vás!
          </Text>
        </Center>
      </BackgroundImage>
      {/* --------------------------------------------------        
      <a target="_blank" href="/JobVorlage">
          <Button className="Button2" variant="subtle">
            Hier findest du deinen Job!
          </Button>
        </a>------------------------------------------------------------------------------*/}
      <BackgroundImage className="WerSindWir" src={JobB}>
        <Row justify="center" align="middle" className="DasMachtUnsGrid">
          <Grid>
            <Grid.Col span={7} className="WerSindWirsLinks">
              <a
                href="https://at.linkedin.com/company/fb-consulting-trade-gmbh"
                target="_blank"
              >
                {" "}
                <Image
                  className="WerSindWirBild"
                  src={Card1}
                />
              </a>
            </Grid.Col>
            <Grid.Col span={5} className="WerSindWirRechts">
              <Center p="md"></Center>
              Našim zákazníkom ponúkame IT služby na mieru.
              zákazníci. Od riadenia vašich IT projektov až po vývoj softvéru
              softvér, postaráme sa o všetky vaše IT služby a vy môžete
              sa môžete sústrediť na svoju hlavnú činnosť. Garantujeme
              TOP služby pre riadenie projektov, vývoj softvéru a
              testovanie softvéru.
            </Grid.Col>
          </Grid>
        </Row>
      </BackgroundImage>
      {/* --------------------------------------------------------------------------------------------------------------------------------*/}
      <BackgroundImage className="WasMachtUns" src={JobB2}>
        <Center className="DasMachtUnsGridH1" p="md">
          {" "}
          <h1>Výhody</h1>
        </Center>
        <Row justify="center">
          <Col className="colCenter" span={4}>
          cielené plánovanie kariéry
            <p>
              <FaRoute />
            </p>
          </Col>
          <Col className="colCenter" span={4}>
          Atraktívne odmeňovanie
            <p>
              <FaEuroSign />
            </p>
          </Col>
          <Col className="colCenter" span={4}>
          Ľahko dostupná kancelária
            <p>
              <FaTrain />
            </p>
          </Col>
        </Row>
        <Row justify="center">
          <Col className="colCenter" span={4}>
          Praktická mentalita
            <p>
              <FaHandsHelping />
            </p>
          </Col>
          <Col className="colCenter" span={4}>
          Ploché hierarchie
            <p>
              <FaWindowMinimize />
            </p>
          </Col>
          <Col className="colCenter" span={4}>
          Flexibilný pracovný čas
            <p>
              <FaUserClock />
            </p>
          </Col>
        </Row>
        <Row justify="center">
          <Col className="colCenter" span={4}>
          Bezplatné nápoje a občerstvenie
            <p>
              <FaPizzaSlice />
            </p>
          </Col>
          <Col className="colCenter" span={4}>
            {" "}
            Vývojárske zápisníky
            <p>
              <FaLaptop />
            </p>
          </Col>
          <Col className="colCenter" span={4}>
            {" "}
            Homeoffice
            <p>
              <FaLaptopHouse />
            </p>
          </Col>
        </Row>
      </BackgroundImage>
      {/* --------------------------------------------------------------------------------------------------------------------------------*/}
      <Carousel
        effect="fade"
        afterChange={onChange}
        className="GallerieBewerber"
        autoplay
        autoplaySpeed={3000}
      >
        <div id="Seite1">
          <Row justify="center" align="middle">
          <Col span={4} className="ZitatBild">
              <a href="https://www.wienenergie.at" target="_blank">
                {" "}
                <Image className="ZitatBildGroesse" src={wienE} />{" "}
              </a>
            </Col>
            <Col span={4} className="ZitatBild">
              <a href="https://www.brz.gv.at" target="_blank">
                {" "}
                <Image className="ZitatBildGroesse" src={brz} />{" "}
              </a>
            </Col>
            <Col span={4} className="ZitatBild">
              <a href="https://www.post.at" target="_blank">
                {" "}
                <Image className="ZitatBildGroesse" src={post} />{" "}
              </a>
            </Col>
          </Row>
        </div>

        <div id="Seite1">
          <Row justify="center" align="middle">
            <Col span={4} className="ZitatBild">
              <a href="https://www.unex.at" target="_blank">
                {" "}
                <Image className="ZitatBildGroesse" src={unex} />{" "}
              </a>
            </Col>
            <Col span={4} className="ZitatBild">
              <a href="https://www.remax.at" target="_blank">
                {" "}
                <Image className="ZitatBildGroesse" src={remax} />{" "}
              </a>
            </Col>
            <Col span={4} className="ZitatBild">
              <a href="https://www.zurich.at" target="_blank">
                {" "}
                <Image className="ZitatBildGroesse" src={zurich} />{" "}
              </a>
            </Col>
          </Row>
        </div>
        <div id="Seite1">
          <Row justify="center" align="middle">
            <Col span={4} className="ZitatBild">
              <a href="http://www.achem.de" target="_blank">
                {" "}
                <Image className="ZitatBildGroesse" src={achem} />{" "}
              </a>
            </Col>
            <Col span={4} className="ZitatBild">
              <a href="https://www.etron.at" target="_blank">
                {" "}
                <Image className="ZitatBildGroesse" src={etron} />{" "}
              </a>
            </Col>
            <Col span={4} className="ZitatBild">
              <a href="http://www.connect7.at" target="_blank">
                {" "}
                <Image className="ZitatBildGroesse" src={connect7} />{" "}
              </a>
            </Col>
          </Row>
        </div>
        <div id="Seite1">
          <Row justify="center" align="middle">

            <Col span={4} className="ZitatBild">
              <a href="https://karasan.kerembasak.at" target="_blank">
                {" "}
                <Image className="ZitatBildGroesse" src={karasan} />{" "}
              </a>
            </Col>
            <Col span={4} className="ZitatBild">
              <a href="http://www.pam1070.com" target="_blank">
                {" "}
                <Image className="ZitatBildGroesse" src={pam} />{" "}
              </a>
            </Col>
            <Col span={4} className="ZitatBild">
              <a href="https://lapausa.at" target="_blank">
                {" "}
                <Image className="ZitatBildGroesse" src={laPausa} />{" "}
              </a>
            </Col>
          </Row>
        </div>
      </Carousel>
      {/* --------------------------------------------------------------------------------------------------------------------------------*/}
      <BackgroundImage className="WasMachtUns" src={JobB}>
        <Center p="md">
          <Text className="WasMachtUnsText" color="#fff">
          Aktuálne voľné pracovné miesta
          </Text>
        </Center>
        <Center p="md">
          <div justify="space-around" align="middle" className="AktuellGrid">
            <p>
              <a href="/Softwaretester">
                <Button className="ButtonJob" variant="subtle">
                Tester softvéru
                </Button>
              </a>

              <a href="/Projektmanager">
                <Button className="ButtonJob" variant="subtle">
                Projektový manažér
                </Button>
              </a>
            </p>

            <p>
              <a href="/SoftwareentwicklerJ">
                <Button className="ButtonJob" variant="subtle">
                Vývojár softvéru Java
                </Button>
              </a>
              <a href="/SoftwareentwicklerC">
                <Button className="ButtonJob" variant="subtle">
                Vývojár softvéru C#
                </Button>
              </a>
            </p>
          </div>
        </Center>
      </BackgroundImage>
      {/* --------------------------------------------------------------------------------------------------------------------------------*/}
      <BackgroundImage className="WasMachtUns" src={JobB2}>
        <Row className="DeinNeuerJob">
          <Col xl={{ span: 5, offset: 2 }}>
            <h1>Porozprávajme sa...</h1>
            <p>..o vašej budúcej práci..</p>
          </Col>
          <Col xl={{ span: 3, offset: 0 }} lg={{ span: 6, offset: 0 }}>
            <a
              href="https://www.linkedin.com/in/merve-celik-52a053163/"
              target="_blank"
            >
              <Image className="MerveB" src={Merve} />{" "}
            </a>
          </Col>
          <Col xl={{ span: 4, offset: 0 }}>
            <h2>Merve Celik</h2>
            <p>Vaša kontaktná osoba pre otázky týkajúce sa vašej novej práce!</p>
            <a href="mailto:merve.celik@fb-c.at">
              <MailOutlined /> email: merve.celik@fb-c.at
            </a>
            <p>
              <a href="tel:06602906057">
                <PhoneOutlined /> phone number: +43 660 2906057
              </a>
            </p>
          </Col>
        </Row>
      </BackgroundImage>
    </>
  );
}

export default Bewerber;

