import React from "react";
import Navbar2 from "../../components/Navbar2";
import "../../styles/JobVorlage.css";
import JobVorlageB from "../../assets/JobVorlageB.jpg";
import Merve from "../../assets/merve.jpg";
import BewerberB from "../../assets/aboutBack.jpg";
import { Row, Col } from "antd";
import Video0 from "../../components/video0";
import {
  EuroOutlined,
  CoffeeOutlined,
  DribbbleOutlined,
  HomeOutlined,
  LaptopOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

import {
  Image,
  Text,
  BackgroundImage,
  Center,
} from "@mantine/core";

function SoftwareentwicklerC() {
  return (
    <>
      <Navbar2 />
      <Video0 />
      <BackgroundImage className="JobBild" src={JobVorlageB}>
        <Center p="md">
          <Text className="JobVorlageText" color="#fff">
            C# Vývojár softvéru
            <p>Viedeň | Plný úväzok</p>
          </Text>
        </Center>
      </BackgroundImage>
      {/* ------------------------------------------------Links--------------------------------------------------------------------------------*/}
      <BackgroundImage className="WasMachtUns" src={BewerberB}>
        <Row className="Divider">
          <Col span={9} className="JobLinks">
            <ul align="left">
              <h2>Vaše úlohy:</h2>
              <li>Návrh a implementácia backendových aplikácií</li>
              <li>
              Nový alebo ďalší vývoj softvérových riešení a služieb
                so zameraním na jazyk C#
              </li>
              <li>Testovanie nových funkcií produktu</li>
              <li>Spolupráca na zaujímavých, inovatívnych projektoch</li>
            </ul>

            <ul align="left">
              <h2>Inšpirujete nás:</h2>
              <li>
              Ukončené štúdium so zameraním na IT alebo rovnocenné
                Vzdelávanie
              </li>
              <li>Najmenej 2 roky odbornej praxe</li>
              <li>
              Znalosť jazyka SQL (MS SQL-Server / T-SQL), modelovania údajov a
                C# / .NET
              </li>
              <li>
              Znalosť ASP.NET, ASP.NET MVC a Javascriptu (JQuery)
              </li>
              <li>
              Ideálne je, ak máte znalosti Containieru s Dockerom a
                Správa zdrojového kódu v GitLab / GitHub
              </li>
              <li>Pripojenie k rozhraniam REST, SOAP, JSON</li>
              <li>
              Znalosť HTML 5, JavaScript, Entity Framework, Microsoft
                TFS
              </li>
              <li>Presný a starostlivý spôsob práce</li>
              <li>
              Veľmi dobrá znalosť nemčiny a angličtiny v písanej aj hovorenej forme
              </li>
              <li>Vývoj backendových komponentov pomocou .NET</li>
            </ul>
            {/* --------------------------------------------Rechts------------------------------------------------------------------------------------*/}
          </Col>
          <Col span={9} className="Icons">
            <ul align="left">
              <h2>Čo vám ponúkame :</h2>
              <li>
              cielené plánovanie kariéry a vynikajúce
                možnosti rozvoja (platené certifikácie a
                školenia)
              </li>
              <li>Atraktívne odmeňovanie</li>
              <li>
              Verejne prístupná kancelária - priamo uprostred Mariahilfer
                Ulica
              </li>
              <li>Praktická mentalita</li>
              <li>Zamestnanecké podujatia</li>
              <li>ploché hierarchie</li>
              <li>Flexibilný pracovný čas a možnosť home office</li>
              <li>
              Bezplatné nápoje a občerstvenie podľa vlastného výberu a ďalšie výhody
              </li>
              <li>
              K dispozícii sú najnovšie vývojárske notebooky
              </li>
              <li>Možnosť až 100% home office</li>
            </ul>

            <EuroOutlined />
            <CoffeeOutlined />
            <DribbbleOutlined />
            <HomeOutlined />
            <LaptopOutlined />

            <p>
            Za túto pozíciu ponúkame odmenu podľa
              kolektívna zmluva v oblasti IT. V závislosti od konkrétnej kvalifikácie a
              skúsenosti, existuje ochota preplácať.
            </p>
            <Image className="MerveB2" width={180} height={180} src={Merve} />
            <h2>Merve Celik</h2>
            <p>Vaša kontaktná osoba pre otázky týkajúce sa vašej novej práce!</p>
            <a href="mailto:merve.celik@fb-c.at">
              <MailOutlined /> email: merve.celik@fb-c.at
            </a>
            <p>
            <a href="tel:06602906057">
              <PhoneOutlined /> phone number: +43 660 2906057
            </a>
            </p>
          </Col>
        </Row>
      </BackgroundImage>

      {/* --------------------------------------------------------------------------------------------------------------------------------*/}

      {/* --------------------------------------------------------------------------------------------------------------------------------*/}
    </>
  );
}

export default SoftwareentwicklerC;
