import React from "react";
import "../styles/About.css";
import {
  Card,
  Image,
  Text,
  Badge,
  Button,
  Group,
  Divider,
  useMantineTheme,
  Spoiler,
} from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";
import { Link } from "react-scroll";
import aboutBack2 from "../assets/aboutBack2.jpg";
import JobB from "../assets/jobB.jpg";

function About() {
  return (
    <>
      <div
        id="aboutID"
        style={{
          backgroundImage: `url(${JobB})`,
          backgroundSize: "100% 100%",
          height: "auto",
          zIndex: 1,
        }}
      >
        <h3 className="ÜberUnsHome">O nás</h3>
        <div className="FadeDownCards" id="example">
          <div
            className="CardsChange"
            style={{ width: "35%", margin: "0 2vh 0 0" }}
            data-aos="fade-down"
            data-aos-delay="250"
            data-aos-duration="400"
            data-aos-easing="ease-in-cubic"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor="#example"
          >
            <Card shadow="sm" padding="lg">
              <Card.Section>
                <Image
                  src={require("../assets/Card1.jpg")}
                  height={160}
                  alt="Norway"
                />
              </Card.Section>

              <div
                data-aos="fade-down"
                data-aos-delay="250"
                data-aos-duration="400"
                data-aos-easing="ease-in-cubic"
                data-aos-mirror="true"
                data-aos-once="false"
                data-aos-anchor="#example"
              >
                <Group
                  position="apart"
                  style={{ marginBottom: 5, marginTop: 5 }}
                >
                  <Text className="CardTitel">Naši zákazníci</Text>
                  <Text className="CardTitel2">

                  </Text>
                </Group>

                <Spoiler
                  maxHeight={120}
                  showLabel="Mehr Erfahren"
                  hideLabel="Verstecken"
                >
                  {
                    <Text size="sm" className="CardText">
                       V posledných rokoch sme mali za úlohu poskytovať
                      zákazníkom softvérové riešenia a pomôcť ešte väčšiemu počtu zákazníkov
                      a podporiť ešte viac zákazníkov pri realizácii ich individuálnych
                      jednotlivé projekty. Na
                      na nasledujúcej stránke nájdete prehľad našich
                      zákazníci a projekty.
                    </Text>
                  }
                </Spoiler>
              </div>
            </Card>
          </div>
          <div
            className="CardsChange"
            style={{ width: "35%", margin: "0 0 0 0" }}
            data-aos="fade-down"
            data-aos-delay="500"
            data-aos-duration="400"
            data-aos-easing="ease-in-cubic"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor="#example"
          >
            <Card shadow="sm" padding="lg">
              <Card.Section>
                <Image
                  src={require("../assets/Card2.jpg")}
                  height={160}
                  alt="Norway"
                />
              </Card.Section>
              <div
                data-aos="fade-down"
                data-aos-delay="500"
                data-aos-duration="400"
                data-aos-easing="ease-in-cubic"
                data-aos-mirror="true"
                data-aos-once="false"
                data-aos-anchor="#example"
              >
                <Group
                  position="apart"
                  style={{ marginBottom: 5, marginTop: 5 }}
                >
                  <Text className="CardTitel">Služby</Text>
                  <Text className="CardTitel2">
                    
                  </Text>
                </Group>
                <Spoiler
                  maxHeight={120}
                  showLabel="Mehr Erfahren"
                  hideLabel="Verstecken"
                >
                  {
                    <Text size="sm" className="CardText">
                      Portfólio služieb našej spoločnosti zahŕňa
                      celú oblasť vývoja softvéru. Stránka
                      hlavné piliere našej ponuky sú
                      vývoj softvéru, testovanie softvéru a riadenie projektov.
                      Neváhajte nás kontaktovať, aby sme s vami prediskutovali vaše
                      osobne o vašich potrebách a požiadavkách.
                      sa s nami porozprávajte.

                    </Text>
                  }
                </Spoiler>
              </div>
            </Card>
          </div>
          {/* ------------------------------------------------------------------------------------------------------------------------------
          <div
            className="CardsChange"
            style={{ width: "25%", margin: "20vh 0 0 0" }}
            data-aos="fade-down"
            data-aos-delay="750"
            data-aos-duration="400"
            data-aos-easing="ease-in-cubic"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor="#example"
          >
            <Card shadow="sm" padding="lg">
              <Card.Section>
                <Image
                  src={require("../assets/Card4.jpg")}
                  height={160}
                  alt="Norway"
                />
              </Card.Section>
              <div
                data-aos="fade-down"
                data-aos-delay="750"
                data-aos-duration="400"
                data-aos-easing="ease-in-cubic"
                data-aos-mirror="true"
                data-aos-once="false"
                data-aos-anchor="#example"
              >
                <Group
                  position="apart"
                  style={{ marginBottom: 5, marginTop: 5 }}
                >
                  <Text className="CardTitel">Zertifizierungen</Text>
                  <Text className="CardTitel2">
                    <h5>Qualitätsstandards und Mitarbeiterentwicklung</h5>
                  </Text>
                </Group>
                <Spoiler
                  maxHeight={120}
                  showLabel="Mehr Erfahren"
                  hideLabel="Verstecken"
                >
                  {
                    <Text size="sm" className="CardText">
                      Die Entwicklung und Weiterbildung unserer Mitarbeiter und
                      die Erfüllung höchster Qualitätsstandards sind wesentliche
                      Augenmerke unserer Unternehmensphilosophie. Aus diesem
                      Grund sind wir stehts bemüht unsere Mitarbeiter mit den
                      führenden Zertifizierungen in ihrem jeweiligen
                      Kompetenzfeld auszustatten. So sind unter anderen unsere
                      Tester nach ISTQB und unsere Projektmanager nach IPMA
                      zertifiziert.
                    </Text>
                  }
                </Spoiler>
              </div>
            </Card>
          </div>
                ----------------------------------------------------------------------------------------------------------------------------*/}
        </div>
      </div>
    </>
  );
}

export default About;
